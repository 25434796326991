
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { InfoCard } from '../../../../../../shared/ui/designSystem/card';
import { FormattedCurrencyYAxis, FormattedKmsXAxis, RechartsCustomTooltip } from '../../../../../../shared/ui/recharts/recharts';
import { type Listing, orderListingByKms } from '../../../../../modules/listings/domain/listings';
import { ListingComparisonTable } from './ListingComparisonTable';

export const AnalysisByKms = ({ listings }: { listings: Listing[] }) => {
  const listingsByKms = listings.toSorted(orderListingByKms);

  return (
    <section className={'pt-4 pb-16 md:pt-16 px-4 sm:px-8 md:px-12 lg:px-24 xl:px-48'}>
      <InfoCard title={'Analisis por kms'} className={'bg-accent dark:bg-card'}>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <LineChart data={listingsByKms}>
              <Line type="monotone" dataKey="price" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="kms" tick={FormattedKmsXAxis} height={70} />
              <YAxis tick={FormattedCurrencyYAxis} width={75} domain={['auto', 'auto']} />
              <Tooltip content={RechartsCustomTooltip} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </InfoCard>

      <ListingComparisonTable listings={listingsByKms} orderBy="kms" />
    </section>
  );
};
