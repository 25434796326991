import Image from 'next/image';
import { ReactElement } from 'react';
import { ContentType } from 'recharts/types/component/Tooltip';
import { Formatter } from '../../../../../contexts/coche-ya/shared/Formatter';

export const RechartsCustomTooltip: ContentType<string[], any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p className='label'>{`Year: ${label}`}</p>
        <p className='label'>{`${payload[0].name}: ${payload[0].payload.humanReadablePrice}`}</p>
        <p className='intro'>{`Kms: ${payload[0].payload.humanReadableKms}`}</p>
        <Image src={payload[0].payload.img} alt='car' height={200} width={200} />
      </div>
    );
  }

  return null;
};

export type RechartsAxis = (props: {
  x: number;
  y: number;
  payload: any;
}) => ReactElement<SVGElement>;
export const FormattedCurrencyYAxis: RechartsAxis = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={0} textAnchor='end' fill='#666'>
        {Formatter.currency(payload.value)}
      </text>
    </g>
  );
};

export const FormattedKmsXAxis: RechartsAxis = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={25} y={10} textAnchor='end' fill='#666'>
        {Formatter.kms(payload.value)}
      </text>
    </g>
  );
};
