'use client';
import { Suspense, useState } from 'react';
import type { Listing } from '../../modules/listings/domain/listings';
import { getListings } from '../../modules/listings/infrastructure/restListingsService';
import { getModels } from '../../modules/models/infrastructure/modelsService';
import { getVersion } from '../../modules/versions/infrastructure/HttpVersionService';
import { SearchProvider } from './AnalyzerContext';
import { ListingsSkeleton } from './features/listingAnalysis/components/ListingsSkeleton';
import { ListingAnalysis } from './features/listingAnalysis/ListingAnalysis';
import { Searcher } from './features/searcher/Searcher';

export default function ListingAnalyzerPage() {
  const [listings, setListings] = useState<Listing[]>();
  const [loading, setIsLoading] = useState(false)
  const [isDiscoveringListings, setIsDiscoveringListings] = useState(false)

  return (
    <SearchProvider getModels={getModels} getListings={getListings} getVersion={getVersion}>
      <section className="flex flex-col">
        {/* necessary to useSearchParams */}
        <Suspense fallback={null}>
          <Searcher setListings={setListings} setIsLoading={setIsLoading} setIsDiscoveringListings={setIsDiscoveringListings} />
          <hr className={'mx-80 invisible dark:visible'} />
          {loading ? <ListingsSkeleton /> : listings ? <ListingAnalysis listings={listings} /> : null}
        </Suspense>
      </section>
    </SearchProvider >
  );
}
