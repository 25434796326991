import { Card, CardContent, CardHeader, CardTitle } from '@/contexts/shared/ui/designSystem/card';
import { H2 } from '@/contexts/shared/ui/designSystem/headings';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/contexts/shared/ui/designSystem/table';

import { Formatter } from '../../../../../../../../contexts/coche-ya/shared/Formatter';
import { type Listing, groupListingsByKms, groupListingsByYear, orderListingByKms, orderListingByYear } from '../../../../../modules/listings/domain/listings';

export const SmartAnalysis = ({ listings }: { listings: Listing[] }) => {
  return (
    <section className={'flex-col mx-4 md:py-16 bg-accent dark:bg-background'}>
      <header className="flex flex-wrap justify-center gap-8">
        <H2 className="w-full max-w-md">Smart analysis</H2>
        <div className="w-full max-w-md"></div>
      </header>
      <div className="flex flex-wrap justify-center gap-8">
        <AnalysisByKms listings={listings} />
        <AnalysisByYears listings={listings} />
      </div>
    </section>
  );
};

const AnalysisByKms = ({ listings }: { listings: Listing[] }) => {
  const groupedByYear = groupListingsByYear(listings).filter(car => car.length > 1);

  return (
    <Card className="w-full max-w-md">
      <CardHeader>
        <CardTitle>Depreciación por kms</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Modelo</TableHead>
              <TableHead>Cambia</TableHead>
              <TableHead>Cada</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {groupedByYear.map(carListings => {
              const listingsByKms = [...carListings].sort(orderListingByKms);
              const kmsDiff = listingsByKms[listingsByKms.length - 1].kms - listingsByKms[0].kms;
              const priceDiff =
                listingsByKms[listingsByKms.length - 1].price - listingsByKms[0].price;
              const kmsTick = kmsDiff / 4;
              const priceTick = priceDiff / 4;

              return (
                <TableRow key={carListings[0].kms}>
                  <TableCell>
                    {carListings[0].name} de {carListings[0].year}
                  </TableCell>
                  <TableCell>{Formatter.currency(priceTick)}</TableCell>
                  <TableCell>{Formatter.kms(kmsTick)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const AnalysisByYears = ({ listings }: { listings: Listing[] }) => {
  const { groupedListings, kmsRangePerGroup } = groupListingsByKms(listings);
  const groupedByKms = groupedListings.filter(car => car.length > 1);

  return (
    <Card className="w-full max-w-md">
      <CardHeader>
        <CardTitle>Depreciación por año</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Modelo y Rango de Kilometraje</TableHead>
              <TableHead>Cambia</TableHead>
              <TableHead>Despues de</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {groupedByKms.map(carListings => {
              const listingsByYears = [...carListings].sort(orderListingByYear);

              const yearsDiff =
                listingsByYears[listingsByYears.length - 1].year - listingsByYears[0].year;
              const priceDiff =
                listingsByYears[listingsByYears.length - 1].price - listingsByYears[0].price;
              const yearsTick = yearsDiff;
              const priceTick = priceDiff / yearsTick;

              if (yearsTick === 0) {
                return (
                  <TableRow key={carListings[0].kms + carListings[0].id}>
                    <TableCell key={carListings[0].kms}>
                      Hay {carListings.length} {carListings[0].name} de{' '}
                      {carListings[0].humanReadableKms} hasta{' '}
                      {Formatter.kms(carListings[0].kms + kmsRangePerGroup)} de{' '}
                      {carListings[0].year}
                    </TableCell>
                    <TableCell>{Formatter.currency(priceDiff)}</TableCell>
                    <TableCell>{0} años</TableCell>
                  </TableRow>
                );
              }
              return (
                <TableRow key={carListings[0].kms + carListings[0].id}>
                  <TableCell>
                    {carListings[0].name} de {carListings[0].humanReadableKms} hasta{' '}
                    {Formatter.kms(carListings[0].kms + kmsRangePerGroup)}
                  </TableCell>
                  <TableCell>{Formatter.currency(priceTick)}</TableCell>
                  <TableCell>{yearsTick} años</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
