import { WithChildren } from '@/contexts/shared/ui/designSystem/headings';
import { createContext, FC, useContext } from 'react';
import type { ListingsService } from '../../modules/listings/domain/ListingsService';
import type { ModelsService } from '../../modules/models/domain/modelService';
import type { VersionService } from '../../modules/versions/domain/VersionService';

interface AnalyzerContext {
  getModels: ModelsService['getModels'];
  getListings: ListingsService['getListings'];
  getVersion: VersionService['getVersion'];
}

const AnalyzerContext = createContext<AnalyzerContext | undefined>(undefined);

export const useSearchContext = () => {
  const context = useContext(AnalyzerContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};

export const SearchProvider: FC<WithChildren<AnalyzerContext>> = ({ children, ...props }) => {
  return <AnalyzerContext.Provider value={{ ...props }}>{children}</AnalyzerContext.Provider>;
};
