'use client';
import type { Listing } from '../../../../modules/listings/domain/listings';
import { AnalysisByKms } from './components/AnalysisByKms';
import { AnalysisByYear } from './components/AnalysisByYear';
import { Listings } from './components/Listings';
import { SmartAnalysis } from './components/SmartAnalysis';

export function ListingAnalysis({ listings }: { listings: Listing[] }) {
  return (
    <div className="flex flex-col">
      <Listings listings={listings} />
      <SmartAnalysis listings={listings} />
      <AnalysisByYear listings={listings} />
      <AnalysisByKms listings={listings} />
    </div>
  );
}
