import { InfoCard } from '@/contexts/shared/ui/designSystem/card';
import { RechartsCustomTooltip } from '@/contexts/shared/ui/recharts/recharts';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { Formatter } from '../../../../../../../../contexts/coche-ya/shared/Formatter';
import { orderListingByYear, type Listing } from '../../../../../modules/listings/domain/listings';
import { ListingComparisonTable } from './ListingComparisonTable';

export const AnalysisByYear = ({ listings }: { listings: Listing[] }) => {
  const listingsByYear = listings.toSorted(orderListingByYear);

  return (
    <section
      className={'py-16 px-4 sm:px-8 md:px-12 lg:px-24 xl:px-48 bg-accent dark:bg-background'}
    >
      <InfoCard title={'Analisis por año'}>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <ComposedChart data={listingsByYear}>
              <Line type="monotone" dataKey="price" stroke="#8884d8" r={4} yAxisId={'left'} />
              <Bar dataKey="kms" barSize={20} fill="#413ea0" yAxisId={'right'} />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="year" />
              <YAxis
                tickFormatter={Formatter.currency}
                width={75}
                yAxisId="left"
                scale={'auto'}
                interval={'preserveEnd'}
              />
              <YAxis
                domain={([, dataMax]) => [0, dataMax * 3]}
                tickCount={0}
                yAxisId="right"
                orientation="right"
              />
              <Tooltip content={RechartsCustomTooltip} />
              <Legend />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </InfoCard>

      <ListingComparisonTable listings={listingsByYear} orderBy={'years'} />
    </section>
  );
};
