import { useSearchParams } from 'next/navigation';
import { useState } from 'react';

const setQueryParam = (key: string, value?: string) => {
  if (typeof window !== 'undefined') {
    const queryParams = new URLSearchParams(window.location.search);
    value ? queryParams.set(key, value) : queryParams.delete(key);
    window.history.replaceState(null, '', '?' + queryParams.toString());
  }
};

export const useSearcherParams = () => {
  const queryParams = useSearchParams();
  const [maker, setMaker] = useState(queryParams.get('maker') ?? undefined);
  const [model, setModel] = useState(queryParams.get('model') ?? undefined);
  const [version, setVersion] = useState(queryParams.get('version') ?? undefined);

  const selectMaker = (maker: string) => {
    selectModel(undefined);
    version !== 'all' ? selectVersion(undefined) : null;
    setQueryParam('maker', maker);
    setMaker(maker);
  };

  const selectModel = (model?: string) => {
    version !== 'all' ? selectVersion(undefined) : null;
    setQueryParam('model', model);
    setModel(model);
  };

  const selectVersion = (version?: string) => {
    setQueryParam('version', version);
    setVersion(version);
  };

  return { maker, selectMaker, model, selectModel, version, selectVersion };
};
