'use client';
import { useEffect, useState } from 'react';

export const LoadingVersions = ({ loading }: { loading: boolean }) => {
  const [dots, setDots] = useState("")

  useEffect(() => {
    const interval = setInterval(() => {
      if (loading)
        setDots((prevDots) => {
          if (prevDots.length >= 3) {
            return ""
          }
          return prevDots + "."
        })
    }, 500)

    return () => clearInterval(interval)
  }, [loading])


  return (
    <p className={`${loading ? "opacity-1" : "opacity-0"} flex mx-4 my-2 md:pb-6 sm:justify-center sm:self-center text-gray-400`}> Buscando versiones de tu coche{dots}</p>
  );
}
