import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useSearcherParams } from './useSearcherParams';

const FormSchema = z.object({ maker: z.string(), model: z.string(), version: z.string() });

export const useSearcherForm = () => {
  const { maker, selectMaker, model, selectModel, version, selectVersion } = useSearcherParams();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: { maker: maker, model: model, version: version }
  });

  return {
    form,
    maker,
    selectMaker,
    model,
    selectModel,
    version,
    selectVersion
  };
};
