import { useQuery } from '@/contexts/shared/modules/useQuery';

import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { type Listing } from '../../../../../modules/listings/domain/listings';
import { useSearchContext } from '../../../AnalyzerContext';

//  get versions run the discovery
// get listings bring and show the listings
export const useSearcher = (
  setListings: (listings: Listing[]) => void,
  setIsLoading: (loading: boolean) => void,
  maker?: string,
  model?: string,
  version?: string
) => {
  const { getListings } = useSearchContext();
  const client = useQueryClient();
  const areVersionsLoading = client.getQueryState(['versions', model]);

  const {
    data: listingsResponse,
    refetch,
    isLoading
  } = useQuery({
    queryKey: ['initialListings'],
    queryFn: () => getListings(maker!, model!, version!),
    enabled: Boolean(maker) && Boolean(model) && Boolean(version)
  });

  useEffect(() => {
    if (listingsResponse && maker && model) {
      setListings(listingsResponse.listings);
    }
  }, [listingsResponse]);

  useEffect(() => {
    if (version) {
      refetch();
    }
  }, [version]);

  useEffect(() => {
    if (model && version === 'all' && areVersionsLoading?.status === 'success') {
      refetch();
    }
  }, [model, areVersionsLoading?.status]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, listingsResponse]);
};
