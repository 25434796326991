'use client';
import { Form, FormField } from '@/contexts/shared/ui/designSystem/form';
import { testMakers } from '../../../../../../../contexts/coche-ya/makers/domain/maker';
import { H1 } from '../../../../../shared/ui/designSystem/headings';
import type { Listing } from '../../../../modules/listings/domain/listings';
import { LoadingVersions } from '../../components/LoadingVersions';
import { MakerSelect } from './components/MakerSelect';
import { ModelSelect } from './components/ModelSelect';
import { VersionSelect } from './components/VersionSelect';
import { useModels } from './hooks/useModels';
import { useSearcher } from './hooks/useSearcher';
import { useSearcherForm } from './hooks/useSearcherForm';
import { useVersions } from './hooks/useVersions';

type SearcherProps = {
  setListings: (listings: Listing[]) => void;
  setIsLoading: (loading: boolean) => void
  setIsDiscoveringListings: (loading: boolean) => void
};

export function Searcher({ setListings, setIsLoading }: SearcherProps) {
  const { maker, selectMaker, model, selectModel, version, selectVersion, form } =
    useSearcherForm();
  const { models } = useModels(maker);
  const { versions, isLoadingVersions } = useVersions(setIsLoading, model, version)
  useSearcher(setListings, setIsLoading, maker, model, version);

  return (
    <section className={'mx-4'}>
      <H1 className="flex sm:justify-center sm:self-center">Encuentra tu coche</H1>
      <Form {...form}>
        <form className="flex flex-wrap justify-center gap-6">
          <div className={'flex flex-row w-full max-w-md gap-2'}>
            <FormField
              control={form.control}
              name="maker"
              render={() => (
                <MakerSelect makers={testMakers} maker={maker} selectMaker={selectMaker} />
              )}
            />
            <FormField
              control={form.control}
              name="model"
              render={() => <ModelSelect models={models} model={model} selectModel={selectModel} />}
            />
          </div>
          <div className={'w-full max-w-md'}>
            <FormField
              control={form.control}
              name="version"
              render={() => (
                <VersionSelect
                  versions={versions}
                  version={version}
                  selectVersion={selectVersion}
                />
              )}
            />
          </div>
        </form>
        <LoadingVersions loading={isLoadingVersions} />
      </Form>
    </section>
  );
}
