import { Button } from '@/contexts/shared/ui/designSystem/button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/contexts/shared/ui/designSystem/collapsible';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/contexts/shared/ui/designSystem/table';
import React from 'react';
import { Formatter } from '../../../../../../../../contexts/coche-ya/shared/Formatter';
import type { Listing } from '../../../../../modules/listings/domain/listings';

type Props = {
  listings: Listing[];
  orderBy: string;
};

export function ListingComparisonTable(props: Props) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen} className="space-y-4 mt-12">
      <div className="flex items-center justify-center space-x-4 px-4 w-full">
        <CollapsibleTrigger asChild={true}>
          <Button variant="default" size="sm">
            {isOpen ? 'Esconder' : 'mostrar'} tabla con los datos
          </Button>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent>
        <ListingComparisonTableComponent {...props} />
      </CollapsibleContent>
    </Collapsible>
  );
}

function ListingComparisonTableComponent({ listings, orderBy }: Props) {
  return (
    <Table>
      <TableCaption>Comparacion ordenada por {orderBy}</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead>Precio</TableHead>
          <TableHead>Año</TableHead>
          <TableHead>Kms</TableHead>
          <TableHead>Diferencia Kms %</TableHead>
          <TableHead>Diferencia Kms</TableHead>
          <TableHead>Cambio de precio %</TableHead>
          <TableHead>Cambio de precio</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {listings.map(listing => {
          const priceEvolutionByKms = ((listing.kms * 100) / listings[0].kms - 100).toFixed(0);
          const priceEvolution = ((listing.price * 100) / listings[0].price - 100).toFixed(0);
          const priceDiff = listing.price - listings[0].price;
          return (
            <TableRow key={`${listing.id}`}>
              <TableCell className="font-medium">{listing.humanReadablePrice}</TableCell>
              <TableCell>{listing.year}</TableCell>
              <TableCell>{listing.humanReadableKms}</TableCell>
              <TableCell
                className={Number(priceEvolutionByKms) < 0 ? 'text-red-600' : 'text-green-600'}
              >
                {priceEvolutionByKms} %
              </TableCell>
              <TableCell>{(listing.kms - listings[0].kms).toFixed(0)}</TableCell>
              <TableCell className={Number(priceEvolution) < 0 ? 'text-red-600' : 'text-green-600'}>
                {priceEvolution} %
              </TableCell>
              <TableCell className={Number(priceDiff) < 0 ? 'text-red-600' : 'text-green-600'}>
                {Formatter.currency(priceDiff)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
