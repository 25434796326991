'use client';


import { H2 } from '@/contexts/shared/ui/designSystem/headings';
import { Skeleton } from '../../../../../../shared/ui/designSystem/skeleton';
import { cn } from '../../../../../../shared/ui/twMerge/twMerge';
import { ListingCardSkeleton } from './ListingSkeleton';

export function ListingsSkeleton() {
  return (
    <section>
      <div className={cn("space-y-4 md:pt-16 mx-4 sm:mx-8 md:mx-12 lg:mx-24 xl:mx-48")}>
        <H2>Coches en venta</H2>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {Array(4).fill(0).map((_, i) => (
            <ListingCardSkeleton key={i} />
          ))}
        </div>
      </div>
      <Skeleton
        className="space-y-4 pb-16 mt-12 mx-4 sm:mx-8 md:mx-12 lg:mx-24 xl:mx-48"
      />
    </section>
  );
}
