'use client';


import { Card, CardContent } from '@/contexts/shared/ui/designSystem/card';
import { Skeleton } from '../../../../../../shared/ui/designSystem/skeleton';


export const ListingCardSkeleton = () => {
  return (
    <Card className="group relative overflow-hidden rounded-lg shadow-md transition-all hover:shadow-lg dark:bg-gray-950">
      <CardContent className="p-0">
        <Skeleton className="h-64 w-full" />
        <div className="p-4 space-y-4">
          <div className="flex items-center justify-between">
            <div className="space-y-2">
              <Skeleton className="h-6 w-36" />
              <Skeleton className="h-4 w-12" />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Skeleton className="h-6 w-6 rounded-full" />
              <Skeleton className="h-5 w-16" />
            </div>
            <Skeleton className="h-7 w-20" />
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Skeleton className="h-6 w-6 rounded-full" />
              <Skeleton className="h-5 w-20" />
            </div>
            <Skeleton className="h-5 w-24" />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
