import { useQuery } from '@/contexts/shared/modules/useQuery';
import { useEffect } from 'react';
import { useSearchContext } from '../../../AnalyzerContext';

export const useModels = (maker?: string) => {
  const { getModels } = useSearchContext();
  const { data: models, refetch } = useQuery({
    queryKey: ['models'],
    queryFn: () => getModels(maker!),
    enabled: Boolean(maker)
  });
  useEffect(() => {
    if (maker) {
      refetch();
    }
  }, [maker]);

  return { models };
};
