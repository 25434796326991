import { useQuery } from '@/contexts/shared/modules/useQuery';
import { useEffect } from 'react';
import { useSearchContext } from '../../../AnalyzerContext';

export const useVersions = (
  setIsLoading: (loading: boolean) => void,
  model?: string,
  version?: string
) => {
  const { getVersion } = useSearchContext();
  const {
    data: versions,
    refetch,
    isLoading
  } = useQuery({
    queryKey: ['versions', model],
    queryFn: () => getVersion(model!),
    enabled: Boolean(model)
  });

  useEffect(() => {
    if (model) {
      refetch();
    }
  }, [model]);

  useEffect(() => {
    if (version === 'all' && isLoading) {
      setIsLoading(isLoading);
    }
  }, [isLoading]);

  return { versions, isLoadingVersions: isLoading };
};
